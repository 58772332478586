<template>
   <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <!-- <h1 class="m-0">Anasayfa</h1> -->
          </div><!-- /.col -->
          <div class="col-sm-6">
            <!-- <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item active"><a href="#">Home</a></li>
            </ol> -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 col-lg-9">
            <div class="card">
              <div class="card-body">
                  <div class="h3">
                    Hoşgeldin, <strong>{{TeacherName}}!</strong>
                  </div>
                  <div class="text-secondary fs-5">{{StudentCount}} adet öğrencin bulunmaktadır. </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <div class="card">
                  <div class="card-body p-0">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th colspan="6">Öğrencilerim</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="s in ActiveStudents.sort((a,b)=>{return parseInt(a.trainingFile.time.replace(':',''))-parseInt(b.trainingFile.time.replace(':',''));})" :key="s">
                            <td style="min-width:55px" class="text-center"><i class="nav-icon fas fa-lg" v-if="s.student.gender>0" :class="{'fa-mars text-primary':s.student.gender==1,'fa-venus text-danger':s.student.gender==2}"></i></td>
                            <td style="min-width:250px" class="text-capitalize">Student {{s.student.name}}</td>
                            <td style="min-width:300px">{{CalculateTimeByOffset(s.trainingFile.time)}} ({{s.trainingFile.duration}} Dk) {{WeekDayNumberToText(s.trainingFile.weekDay)}}</td>
                            <td style="min-width:110px">
                              <span class="badge mr-2 float-left badge-danger" v-if="s.trainingFile.isDemo">Demo</span>
                              <span class="badge mr-2 float-left badge-info" v-if="s.trainingFile.isTryYourself">Kendini Dene</span>
                            </td>
                            <td style="min-width:150px" class="text-center">
                              <div class="progress mt-2">
                                <div class="progress-bar bg-success" style="width: {{s.statistic.rate}}%" aria-valuenow="{{s.statistic.rate}}" aria-valuemin="0" aria-valuemax="{{s.statistic.totalNumberOfLessons}}">%{{s.statistic.rate}}</div>
                              </div>
                            </td>
                            <td class="text-sm align-middle" style="width:60px">{{s.statistic.numberOfLessonsRealized}}/{{s.statistic.totalNumberOfLessons}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.col-md-6 -->
          <div class="col-sm-12 col-lg-3 mb-2">
            <!-- <div class="info-box">
                <span class="info-box-icon bg-info elevation-1"><i class="fas fa-wallet"></i></span>
                <div class="info-box-content">
                  <span class="info-box-text" style="font-size:22px;">Hakediş</span>
                  <span class="info-box-number" style="font-size:32px; line-height:normal;">1.500 <small>₺</small></span>
                </div>
             </div> -->
             <div class="card">
                  <div class="card-header">
                    <strong>Günlük Çalışma</strong>
                  </div>
                  <div class="card-body p-0">
                    <div v-if="WorkTimeData!=null"><DoughnutChart :chart-data="ChartData" /></div>
                    <div class="text-center" v-else><br /><br />Bugün ders bulunamadı.<br /><br /><br /></div>
                  </div>
                </div>
            <!-- <div class="card">
              <div class="card-body">
                  <div class="text-center mb-3">
                    <v-date-picker v-model="date"/>
                  </div>
              </div>
            </div> -->
          </div>
          <!-- /.col-md-6 -->
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->


    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="GeneralModal">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <!-- <div class="modal-header bg-success">
                    <h5 class="modal-title"><i class="fa fa-bullhorn"></i> Duyuru</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div> -->
                <div class="modal-body">
                    <!-- <p>Değerli Öğretmenimiz,<br /><br />Bayram nedeniyle <strong>21 Nisan</strong>’da ders <u>işlenmeyecektir.</u> Dersler tarafımızdan ertelenecektir. <br />Lengedu ailesi olarak sevdiklerinizle mutlu bir bayram geçirmenizi dileriz.</p> -->
                    <!--<p>Değerli Öğretmenimiz,<br /><br />30 Ağustos Zafer Bayramı nedeniyle ders <u>işlenmeyecektir.</u> Dersler tarafımızdan ertelenecektir. <br />Zafer Bayramımız kutlu olsun.</p>-->
                    <p class="text-center">Dünümüzü hatırlatan, bugünümüzü yaşanılır kılan, geleceğimizi aydınlatan Lengedu ailesinin kıymetli öğretmeni<br/><br/><b style="font-size: x-large;">24 Kasım Öğretmenler Günün</b><br/>kutlu olsun. </p>
                    <!-- style="background-color: #1d3423; color:#FFF; border:8px solid #c48c53;" <p class="text-center">Dünümüzü hatırlatan, bugünümüzü yaşanılır kılan, geleceğimizi aydınlatan Lengedu ailesinin kıymetli öğretmeni<br/><br/><b style="font-size: x-large;">24 Kasım Öğretmenler Günün</b><br/>kutlu olsun. </p> -->
                    <!--<p>Değerli Öğretmenimiz,<br /><br />17.06.2024 tarihinden 21.06.2024 tarihine kadar Bayram tatili olacaktır, tatil sonrası ilk dersiniz 24.06.2024 Pazartesi günü olacaktır. Dersleriniz tarafımızca ertelenecektir.<br>Lengedu Online İngilizce Eğitimi Ailesi olarak iyi bayramlar dileriz.</p>-->
                    <!-- <p>Değerli Öğretmenimiz,<br /><br />15.07.2024 tarihi Demokrasi ve Millî Birlik Günü olduğundan ders işlenmeyecektir. Dersler tarafımızca ertelenecektir.</p> -->
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapActions,mapGetters } from 'vuex';
import DoughnutChart from '../../../components/Char.vue';
import {DateFormat,CalculateTime} from "../../../helpers/date-convert-helper";
import {IsShowErrorMessage} from "../../../helpers/error-message-helper";
import {GetGenderText} from "../../../helpers/enum-convert-helper"
export default {
  name: "Home",
  components: { DoughnutChart },
  data() {
    return {
      date: new Date(),
      studentList:[]
    };
  },
  computed:{
    ...mapGetters(["FULLNAME"]),
    StudentCount:function(){
        return this.studentList.length;
    },
    ActiveStudents:function(){
        return this.studentList;
    },
    TeacherName:function(){
        return this.FULLNAME;
    },
    WorkTimeData:function(){
      let lessons = this.studentList.filter(x=>x.lesson!=null);
      let lessonDuration = 0;
      let complatedLessonDuration = 0;
      lessons.forEach(l => {
        if(l.lesson.status==2){
          complatedLessonDuration = complatedLessonDuration + l.trainingFile.duration;
        }else{
          lessonDuration = lessonDuration + l.trainingFile.duration;
        }
      });
      if(lessonDuration==0 && complatedLessonDuration==0){
        return null;
      }else{
        return [lessonDuration,complatedLessonDuration];
      }
    },
    ChartData:function(){
      return {
        labels: [ 'Kalan Eğitim Süresi (Dk)', 'Tamamlanan Eğitim Süresi (Dk)'],
        datasets: [ { 
            backgroundColor: ['#007bff', '#28a745'],
            data: this.WorkTimeData 
            } 
        ]
      }
    }
  },
  methods:{
    ...mapActions(["TEACHER_GETACTIVESTUDENTS"]),
    WeekDayNumberToText(value){
      if(!value)
        return '';

      if(value==2)
        return 'Sal-Per';
      else if(value==3)
        return 'Pzt-Çar-Cum';
      else 
        return 'Hafta içi her gün';

    },
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    GetGender(value){
      return GetGenderText(value);
    },
    CalculateTimeByOffset(value){
        return CalculateTime(value);
    },
    DateFormatForListTitle(value){
      return DateFormat(value,'dd.mm.yyyy');
    },
    GetStudent(){
      this.TEACHER_GETACTIVESTUDENTS()
      .then((res)=>{
            this.studentList = res.data;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    }

  },
  mounted:function(){
    this.GetStudent();
    let finishDate = new Date('2024-11-26 23:59:59');
    let nowDate = new Date();
    if (finishDate > nowDate) {
        setTimeout(function () { window.$('#GeneralModal').modal('show');}, 1000);
    }
  }
};
</script>
